import Enum from './enum'

/**
 * ユーザ在席状態 (検索用)
 */
class ProjectIsActive extends Enum {
    static YES = 1; //アクティブ
    static NO = 0; //終了

    static values() {
        return {
            [this.YES]: 'アクティブ',
            [this.NO]: '終了',
        }
    }
}

export default ProjectIsActive;
