import Enum from './enum'

/**
 * 案件タイプ
 */
class ProjectType extends Enum {
    static ONE_OFF = 1; //単発売上
    static CONTINUOUS = 2; //継続売上
    static TIME_AND_MATERIAL = 3; //工数比例売上
    static BACK_OFFICE = 9; //営業外

    static values() {
        return {
            [this.ONE_OFF]: '単発売上',
            [this.CONTINUOUS]: '継続売上',
            [this.TIME_AND_MATERIAL]: '工数比例売上',
            [this.BACK_OFFICE]: '営業外',
        }
    }
}

export default ProjectType;
