/**
 * 外注先
 */
import { isBlank } from '@/utilities/typing';

class Customer {
    customer_id;
    customer_name;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.customer_id = properties.customer_id;
        this.customer_name = properties.customer_name;
    }
}

export default Customer;
