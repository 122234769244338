/**
 * 案件マスタ：フェーズ
 */
import { isBlank } from '@/utilities/typing';

class Phase {
    phase_id;
    phase_name;
    sales_price;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.phase_id = properties.phase_id;
        this.phase_name = properties.phase_name;
        this.sales_price = properties.sales_price;
    }
}

export default Phase;
