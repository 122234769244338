<template>
    <span class="loading-text" :style="`width:${width}em;`"></span>
</template>

<script>
export default {
    name: 'TextLoader',
    props: {
        width: {
            type: Number,
            default: 10
        },
    }
}
</script>

