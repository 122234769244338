/**
 * 案件マスタ
 */
import { isBlank } from '@/utilities/typing';
import { displayMonth } from '@/utilities/month';
import { numberFormat } from '@/utilities/text-format';
import ProjectType from '@/models/enums/project-type';
import Category from './category';
import Customer from './customer';
import Phase from './phase';
import WorkCategory from './work-category';
import User from './user';
import Department from './department';

class Project {
    project_id;
    project_name;
    customer;
    billing_customer;
    official_name;
    project_type;
    available_from;
    available_to;
    category_1;
    category_2;
    category_3;
    category_4;
    user;
    department;
    monthly_sales;
    labor_unit_sales;
    phases;
    work_categories;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.project_id = properties.project_id;
        this.project_name = properties.project_name;
        this.customer = new Customer(properties.customer);
        this.billing_customer = new Customer(properties.billing_customer);
        this.official_name = properties.official_name;
        this.project_type = properties.project_type;
        this.available_from = properties.available_from;
        this.available_to = properties.available_to;
        this.category_1 = new Category(properties.category_1);
        this.category_2 = new Category(properties.category_2);
        this.category_3 = new Category(properties.category_3);
        this.category_4 = new Category(properties.category_4);
        this.user = new User(properties.user);
        this.department = new Department(properties.department);
        this.monthly_sales = properties.monthly_sales;
        this.labor_unit_sales = properties.labor_unit_sales;
        this.phases = properties.phases;
        this.work_categories = properties.work_categories;

        this.phases = [];
        if ('phases' in properties) {
            this.phases = properties.phases.map((row) => new Phase(row));
        }

        this.work_categories = [];
        if ('work_categories' in properties) {
            this.work_categories = properties.work_categories.map((row) => new WorkCategory(row));
        }
    }

    // タイプ名
    get project_type_label() {
        return ProjectType.get(this.project_type);
    }

    // 開始終了表示
    get available_period() {
        return displayMonth(this.available_from) + "〜" + displayMonth(this.available_to);
    }

    // 売上
    get sales_display() {
        if (this.project_type === ProjectType.ONE_OFF) {
            let sum = this.phases.reduce((sum, row) => sum + row.sales_price, 0);
            return numberFormat(sum) + " 円";
        } else if (this.project_type === ProjectType.CONTINUOUS) {
            return numberFormat(this.monthly_sales) + " 円/月";
        } else if (this.project_type === ProjectType.TIME_AND_MATERIAL) {
            return numberFormat(this.labor_unit_sales) + " 円/H";
        }

        return "-";
    }
}

export default Project;
