/**
 * 外注先
 */
import { isBlank } from '@/utilities/typing';

class Department {
    department_id;
    department_name;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.department_id = properties.department_id;
        this.department_name = properties.department_name;
    }
}

export default Department;
