/**
 * 案件マスタ：作業カテゴリ
 */
import { isBlank } from '@/utilities/typing';

class WorkCategory {
    work_category_id;
    category_name;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.work_category_id = properties.work_category_id;
        this.category_name = properties.category_name;
    }
}

export default WorkCategory;
