<template>
    <nav aria-label="Search results pages">
        <ul class="pagination justify-content-center" v-if="meta.last_page >= 2">
            <li :class="{'disabled': (meta.current_page === 1), 'page-item': true}">
                <a href="#" class="page-link" aria-label="Previous" @click.prevent="moveTo(1)">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <template v-for="page in pages" :key="page">
                <li :class="{ 'active': (meta.current_page === page), 'page-item': true }">
                    <a class="page-link"  @click.prevent="moveTo(page)" style="cursor:pointer;">
                        {{ page }}
                    </a>
                </li>
            </template>
            <li :class="{'disabled': (meta.current_page === meta.last_page), 'page-item': true}">
                <a href="#" class="page-link" aria-label="Next" @click.prevent="moveTo(meta.last_page)">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
        <p class="text-center" v-if="meta.total > 0">{{ meta.total }} 件中 {{ meta.from }} - {{ meta.to }} 件を表示</p>
    </nav>
</template>

<script>
export default {
    name: 'Paginator',
    data() {
        return {
            range: 11

        }
    },
    props: {
        meta: {
            type: Object,
            default: () => {
                return {
                    current_page: null,
                    last_page: null,
                    per_page: null,
                    from: null,
                    to: null,
                    total: null,
                }
            }
        }
    },
    mounted() {

    },
    methods: {
        moveTo(page) {
            this.$emit('move', page);
        }
    },
    computed: {
        pages() {
            let from = this.meta.current_page - 5;
            if (from < 1) {
                from = 1
            }
            let to = this.meta.current_page + 5;
            if (to > this.meta.last_page) {
                to = this.meta.last_page;
            }

            let array = [];
            for (let i = from; i <= to; i++) {
                array.push(i);
            }

            return array;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
