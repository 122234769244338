/**
 * ユーザマスタ
 */
import { isBlank } from '@/utilities/typing'
import { displayMonth } from '@/utilities/month'
import Department from './department';

class User {
    user_id;
    user_name;
    email;
    is_password_temporary;
    member_from;
    member_to;
    permissions;
    department;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.user_id = properties.user_id;
        this.user_name = properties.user_name;
        this.email = properties.email;
        this.is_password_temporary = properties.is_password_temporary;
        this.member_from = properties.member_from;
        this.member_to = properties.member_to;
        this.permissions = properties.permissions ?? [];
        this.department = new Department(properties.department);
    }

    // 在席期間表示
    get member_period() {
        return displayMonth(this.member_from) + "〜" + displayMonth(this.member_to);
    }
}

export default User;
