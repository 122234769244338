<template>
    <header class="page-title">
        <h1><i class="bi bi-list-ul"></i> 案件マスタ</h1>
    </header>

    <section class="section">
        <div class="row mb-3">
            <div class="col-4">
                <form-input v-model="condition.keyword"></form-input>
            </div>
            <div class="col-4">
                <form-select v-model="condition.is_active" :options="ProjectIsActive.options()" empty_option="全て"></form-select>
            </div>
            <div class="col-4">
                <button class="btn btn-primary" @click="search">表示</button>
            </div>
            <div class="col-12 text-end">
                <span v-if="$store.getters['auth/canExportCsv']()" class="dropdown me-3">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-download"></i> CSV
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button" @click="exportCsv()">CSVエクスポート</button></li>
                        <li><router-link :to="{name: 'Export'}" class="dropdown-item">エクスポート履歴</router-link></li>
                    </ul>
                </span>
                <button v-else type="button" class="btn btn-outline-primary me-3" disabled><i class="bi bi-download"></i> CSV</button>
                <router-link class="btn btn-outline-primary" :to="{name: 'MasterProjectAdd'}"><i class="bi bi-plus"></i> 新規登録</router-link>
            </div>
        </div>
        <table class="table">
            <thead class="table-dark text-center">
                <tr>
                    <th>案件名</th>
                    <th>タイプ</th>
                    <th>有効期限</th>
                    <th>受注金額</th>
                    <th>分類</th>
                    <th>担当者</th>
                    <th>部門</th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(3)" :key="i">
                    <td><text-loader :width="10"></text-loader></td>
                    <td><text-loader :width="4"></text-loader></td>
                    <td><text-loader :width="8"></text-loader></td>
                    <td class="text-end"><text-loader :width="8"></text-loader></td>
                    <td><text-loader :width="6"></text-loader></td>
                    <td><text-loader :width="6"></text-loader></td>
                    <td><text-loader :width="6"></text-loader></td>
                    <td class="text-nowrap">
                        <button class="btn btn-primary" disabled>編集</button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="projects.length === 0">
                <tr>
                    <td colspan="5">該当する案件がありません</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="project of projects" :key="project.project_id">
                    <td>{{ project.project_name }}</td>
                    <td>{{ project.project_type_label }}</td>
                    <td>{{ project.available_period }}</td>
                    <td class="text-end">{{ project.sales_display }}</td>
                    <td>{{ project.category_1?.category_name }}<br>{{ project.category_2?.category_name }}<br>{{ project.category_3?.category_name }}<br>{{ project.category_4?.category_name }}</td>
                    <td>{{ project.user?.user_name }}</td>
                    <td>{{ project.department?.department_name }}</td>
                    <td>
                        <router-link class="btn btn-primary" :to="{name: 'MasterProjectEdit', params: {project: project.project_id}}">編集</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <paginator :meta="paginator" @move="search" v-if="paginator"></paginator>
    </section>
</template>

<script>
import Paginator from '@/components/tools/Paginator';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import Project from "@/models/entities/project";
import ProjectIsActive from "@/models/enums/project-is-active";
import TextLoader from '@/components/tools/TextLoader';

export default {
    name: 'MasterProject',
    components: {
        Paginator,
        FormInput,
        FormSelect,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            projects: [],
            loading: false,
            condition: {
                keyword: this.$store.state.pageMaster.keyword,
                is_active: this.$store.state.pageMaster.is_active,
                page: this.$store.state.pageMaster.page,
            },
            ProjectIsActive,
            paginator: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        search(page = 1) {
            this.projects.splice(0);
            this.paginator = null;
            this.loading = true;
            this.condition.page = page;
            this.$store.commit('pageMaster/setKeyword', this.condition.keyword);
            this.$store.commit('pageMaster/setIsActive', this.condition.is_active);
            this.$http.get('/master/project', {params: this.condition})
            .then((response) => {
                this.projects = response.data.data.map((row) => {return new Project(row)});
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        exportCsv() {
            this.startScreenLoading();

            this.$http.post('/master/project/export', {params: this.condition})
            .then(() => {
                this.$router.push({name: 'Export'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
}
</script>

<style scoped>
</style>
